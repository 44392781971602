<template>
  <div>
    <!-- Table Index -->
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :random="true"
    >
    <template #custom_actions="item">
      <b-dropdown-item @click="updateNotification(item.item)">
        <feather-icon icon="ActivityIcon" class="mr-50" />
        <span>{{ $t('labels.update_notification') }}</span>
      </b-dropdown-item>
    </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'Default Match Event Notifications',
      resourceName: 'default-notification-settings',
      singularName: 'default-notification-setting',
      searchFields: {},
    };
  },
  methods: {
    updateNotification(item) {
        this.loading = true

        console.log(item.value)

        const value = item.value === 1 ? 0 : 1

        this.axios
        .patch(`/default-notification-settings/${item.id}`, {
            value
        })
        .then(() => {
            this.$toast.success('Notification updated successfully')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        })
        .catch(error => {
            console.error('Error updating Notification:', error)
            this.$toast.error('Error updating notification')
        })
        .finally(() => {
            this.loading = false
        })
    },
  },
};
</script>
