<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Select Date">
            <b-row>
              <!-- Date Picker -->
              <b-col lg="4" md="6" sm="12">
                <b-form-select
                  v-model="searchFields.date"
                  :options="dateOptions"
                  placeholder="Select a Date"
                />
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group label="Model Details">
            <b-row>
              <b-col lg="4" md="6" sm="12">
                <!-- Model Type -->
                <b-form-group label="Model Type">
                  <b-form-select
                    v-model="searchFields.model_type"
                    :options="[
                      { value: 'App\\Models\\Matches', text: 'Matches' },
                      { value: 'App\\Models\\News', text: 'News' },
                      { value: 'App\\Models\\Analytics', text: 'Analytics' },
                    ]"
                    placeholder="Select Model Type"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="4" md="6" sm="12">
                <!-- Model ID -->
                <b-form-group label="Model ID">
                  <b-form-input
                    v-model="searchFields.model_id"
                    placeholder="Enter Model ID"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Index -->
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';

export default {
  mixins: [listMixin],
  data() {
    const today = new Date();
    const tenDaysAgo = new Date(today);
    tenDaysAgo.setDate(today.getDate() - 10);

    // Generate date options for the past 10 days, including today
    const dateOptions = Array.from({ length: 11 }, (_, i) => {
      const date = new Date(tenDaysAgo);
      date.setDate(date.getDate() + i);
      return { value: date.toISOString().split('T')[0], text: date.toDateString() };
    });

    return {
      dateOptions,
      title: 'notification-logs',
      resourceName: 'notification-logs',
      singularName: 'notification-logs',
      searchFields: {
        date: null,
        model_type: null,
        model_id: null,
      },
    };
  },
  methods: {
    // Method to handle field input changes
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e;  // Update the selected interval ID
    }, 500),
  },
};
</script>
