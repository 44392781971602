<template>
  <div>
    <b-card>
      <validation-observer ref="form">
        <b-card v-if="!isDataLoaded">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.push_notification_matches')"
                vid="name"
                rules="required"
              >
                <b-form-group :label="$t('labels.push_notification_matches')">
                  <b-form-checkbox
                    v-model="form.value"
                    checked="true"
                    value="active"
                    unchecked-value="inactive"
                    name="check-button"
                    switch
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col cols="4 text-left">
              <LoadingButton />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded: false,
      form: {
        key: null,
        value: null,
      },
    }
  },
  created() {
    this.loadMatchesSettings()
  },
  methods: {
    loadMatchesSettings() {
      this.axios
        .get(`/${this.resource}/matches`)
        .then(res => {
          const { data } = res.data
          this.form = {
            key: data.key,
            value: data.value === '1' ? 'active' : 'inactive',
          }
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      return $form
    },
    onSubmit() {
      const value = this.form.value === 'active' ? 1 : 0
      this.axios
        .patch(`/${this.resource}/matches`, {
          value,
        })
        .then(response => {
          this.$store.dispatch('app/isLoading', false)
          const toRoute = this.toRedirectResource ? this.toRedirectResource : this.resource
          this.afterSubmit(response.data.message, `${toRoute}`, {
            resourceId: this.toResourceId,
          })
        })
    },
  },
}
</script>
