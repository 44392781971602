<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.name')"
                vid="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.name')"
                >
                  <b-form-input
                    v-model="form.name"
                    :placeholder="$t('labels.name')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.email')"
                vid="email"
                rules="required|email"
              >
                <b-form-group
                  :label="$t('labels.email')"
                >
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    :placeholder="$t('labels.email')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- password -->
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.match_id')"
              >
                <b-form-group
                  :label="$t('labels.match_id')"
                >
                  <b-form-input
                    v-model="form.match_id"
                    type="text"
                    :placeholder="$t('labels.match_id')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.title')"
              >
                <b-form-group
                  :label="$t('labels.title')"
                >
                  <b-form-input
                    v-model="form.title"
                    type="text"
                    :placeholder="$t('labels.title')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.type')"
              >
                <b-form-group
                  :label="$t('labels.type')"
                >
                  <b-form-input
                    v-model="form.type"
                    type="text"
                    :placeholder="$t('labels.title')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.home_goals')"
                vid="home_goals"
              >
                <b-form-group
                  :label="$t('labels.home_goals')"
                >
                  <b-form-input
                    v-model="form.home_team_score"
                    :placeholder="$t('labels.home_goals')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.away_goals')"
                vid="away_goals"
              >
                <b-form-group
                  :label="$t('labels.away_goals')"
                >
                  <b-form-input
                    v-model="form.away_team_score"
                    :placeholder="$t('labels.away_goals')"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.winning')"
                vid="winning"
              >
                <b-form-group
                  :label="$t('labels.winning')"
                >
                  <b-form-input
                    v-model="form.winning"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.is_draw')"
                vid="is_draw"
              >
                <b-form-group
                  :label="$t('labels.is_draw')"
                >
                  <b-form-input
                    v-model="form.is_draw"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.created_at')"
                vid="created_at"
              >
                <b-form-group
                  :label="$t('labels.created_at')"
                >
                  <b-form-input
                    v-model="form.created_at"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      form: {
        title: null,
        match_id: null,
        name: null,
        email: null,
        type: null,
        home_team_score: null,
        away_team_score: null,
        winning: null,
        is_draw: null,
        created_at: null,
      },
    }
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.match.title,
            match_id: data.match.id,
            name: data.customer.name,
            email: data.customer.email,
            type: data.type,
            home_team_score: data.home_team_score,
            away_team_score: data.away_team_score,
            winning: data.winning ?? '',
            is_draw: data.is_draw,
            created_at: data.created_at,
          }
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      return $form
    },
  },

}
</script>
