<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-form-group :label="$t('labels.id')" label-for="id">
              <b-form-input
                id="id"
                :value="searchFields.id"
                :placeholder="$t('labels.id')"
                @input="inputChange($event, 'id')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-form-group :label="$t('labels.title')" label-for="title">
              <b-form-input
                id="title"
                :value="searchFields.title"
                :placeholder="$t('labels.title')"
                @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t('labels.match_type') }}</label>
            <v-select
              v-model="searchFields.match_type"
              :dir="$store.state.appConfig.layout.direction"
              label="label"
              :options="match_types"
              :reduce="item => item.value"
              :placeholder="$t('labels.match_type')"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t('labels.start_at') }}</label>
            <DatePicker v-model="searchFields.start_at" @input="inputChange($event, 'start_at')" />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :can-change-status="canChangeStatus"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :has-competition="hasCompetition"
      :slotCustomActions="true"
    >
      <template #custom_actions="item">
        <!-- Accessing general action here -->

        <b-dropdown-item v-show="item.item.actions.can_change_status" @click="updateMatchStatus(item.item)">
          <feather-icon icon="ActivityIcon" class="mr-50" />
          <span>{{ $t('labels.update_match_status') }}</span>
        </b-dropdown-item>

        <b-dropdown-item @click="updateMatchDetails(item.item)">
          <feather-icon icon="ActivityIcon" class="mr-50" />
          <span>{{ $t('labels.update_match_details') }}</span>
        </b-dropdown-item>

        <b-dropdown-item @click="recalculateMatchPoints(item.item)">
          <feather-icon icon="ActivityIcon" class="mr-50" />
          <span>{{ $t('labels.recalculate_match_points') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>

    <!-- Modal -->
    <b-modal
      id="change-match-status-modal"
      ref="change-match-status-modal"
      modal-class="modal-primary"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text v-if="canChangeStatus">
        {{ $t('messages.are_you_sure_to_change_status') }}
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-select v-model="form.ts_status_id" :options="statusList" required>
                <template #first>
                  <option :value="null">Select Status</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text v-else>
        {{ $t('messages.not_allowed_to_change_status') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button v-if="canChangeStatus" variant="primary" @click="confirmChangeStatus">
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
    <!-- modal -->
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],

  data() {
    return {
      resourceName: 'matches',
      singularName: 'match',
      hasCompetition: true,
      customField: 'competition',
      canChangeStatus: false,
      match_types: [],
      selectedItem: null,
      searchFields: {
        id: null,
        title: null,
        match_type: null,
        status: null,
        start_at: null,
      },
      statusList: [],
      form: {
        match_id: null,
        ts_status_id: null,
      },
    }
  },
  created() {
    this.getMatchTypes()
    this.getStatusList()
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    getMatchTypes() {
      this.axios.get('/lists/match/types').then(res => {
        this.match_types = res.data
      })
    },
    getStatusList() {
      this.statusList = [
        { value: 1, text: 'Not Started' },
        { value: 2, text: 'First Half' },
        { value: 3, text: 'Half Time' },
        { value: 4, text: 'Second Half' },
        { value: 5, text: 'Over Time' },
        { value: 6, text: 'Over Time Deprecated' },
        { value: 7, text: 'Penalties Shootout' },
        { value: 8, text: 'End' },
      ]
    },
    updateMatchStatus(item) {
      if (item) {
        this.selectedItem = item.item
        this.form.match_id = item.id
        this.canChangeStatus = item.actions.can_change_status
        this.$refs['change-match-status-modal'].show()
      } else {
        console.error('Item ID is missing')
      }
    },
    confirmChangeStatus() {
      if (!this.form.ts_status_id) {
        this.$toast.error('Please select a status before confirming.')
        return
      }

      this.loading = true
      this.axios
        .post(`/urgent-updater/match-status/${this.form.match_id}`, {
          ts_status_id: this.form.ts_status_id,
        })
        .then(() => {
          this.$toast.success('Match status updated successfully')
          this.form.ts_status_id = null
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          this.$refs['change-match-status-modal'].hide()
        })
        .catch(error => {
          console.error('Error updating match status:', error)
          this.$toast.error('Error updating match status')
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateMatchDetails(match) {
      this.loading = true
      this.axios
        .get(`matches-calculation/${match.id}`)
        .then(() => {
          this.$toast.success('Match calculations initated')
        })
        .catch(error => {
          console.error('Error updating match calculations:', error)
          this.$toast.error('Error updating match calculations')
        })
        .finally(() => {
          this.loading = false
        })
    },
    recalculateMatchPoints(match) {
      this.loading = true
      this.axios
        .get(`customer-points-center/refresh-rankings/${match.id}`)
        .then(() => {
          this.$toast.success('Customer rankings updated')
        })
        .catch(error => {
          console.error('Error updating customer rankings:', error)
          this.$toast.error('Error updating customer rankings')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 'Not Started',
          name: this.$t('statuses.not_started'),
        },
        {
          id: 'Match Cancelled',
          name: this.$t('statuses.match_cancelled'),
        },
        {
          id: 'Match Finished',
          name: this.$t('statuses.match_finished'),
        },
        {
          id: 'Time to be defined',
          name: this.$t('statuses.time_to_be_defined'),
        },
        {
          id: 'Match Postponed',
          name: this.$t('statuses.match_postponed'),
        },
        {
          id: 'Halftime',
          name: this.$t('statuses.halftime'),
        },
        {
          id: 'In Progress',
          name: this.$t('statuses.in_progress'),
        },
        {
          id: 'Extra Time',
          name: this.$t('statuses.extra_time'),
        },
        {
          id: 'Second Half',
          name: this.$t('statuses.second_half'),
        },
        {
          id: 'First Half',
          name: this.$t('statuses.first_half'),
        },
        {
          id: 'Penalty In Progress',
          name: this.$t('statuses.penalty_in_progress'),
        },
        {
          id: 'Break Time',
          name: this.$t('statuses.break_time'),
        },
        {
          id: 'Second Half, 2nd Half Started',
          name: this.$t('statuses.second_half_2nd_half_started'),
        },
        {
          id: 'Match Finished After Extra Time',
          name: this.$t('statuses.match_finished_after_extra_time'),
        },
        {
          id: 'First Half, Kick Off',
          name: this.$t('statuses.first_half_kick_off'),
        },
        {
          id: 'Match Suspended',
          name: this.$t('statuses.match_suspended'),
        },
        {
          id: 'Match Interrupted',
          name: this.$t('statuses.match_interrupted'),
        },
        {
          id: 'Match Abandoned',
          name: this.$t('statuses.match_abandoned'),
        },
      ]
    },
  },
}
</script>
