<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                :value="searchFields.email"
                @input="inputChange($event, 'email')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-form-group :label="$t('labels.match_id')" label-for="match_id">
              <b-form-input
                id="id"
                :value="searchFields.id"
                :placeholder="$t('labels.match_id')"
                @input="inputChange($event, 'match_id')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Index -->
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'customer-expectations-logs',
      resourceName: 'customer-expectations-logs',
      singularName: 'customer-expectations-logs',
      searchFields: {
        name: null,
        email: null,
        match_id: null,
      },
    };
  },
  methods: {
    // Method to handle field input changes
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e;  // Update the selected interval ID
    }, 500),
  },
};
</script>
