<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Interval">
            <b-row>
              <!-- Interval Type Selection -->
              <b-col lg="4" md="6" sm="12">
                <b-form-group label="Interval Type">
                  <b-form-select
                    v-model="searchFields.intervalType"
                    @input="fetchIntervalsList"
                    :options="intervalOptions"
                    placeholder="Select Interval Type"
                  />
                </b-form-group>
              </b-col>

              <!-- Intervals List (start_at and end_at) -->
              <b-col lg="4" md="6" sm="12" v-if="intervals.length > 0">
                <b-form-group label="Select Interval">
                  <b-form-select
                    v-model="searchFields.intervalId"
                    @input="inputChange($event, 'intervalId')"
                    :options="intervals"
                    placeholder="Select Interval"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Index -->
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import axios from 'axios';
import listMixin from '@/mixins/listMixin';

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'customer-points-center',
      resourceName: 'customer-points-center',
      singularName: 'customer-points-center',
      searchFields: {
        title: null,
        intervalType: null,  // The selected interval type
        intervalId: null,  // The selected interval ID
      },
      intervalOptions: [
        { value: 1, text: 'Weekly' },
        { value: 2, text: 'Monthly' },
        { value: 3, text: 'Season' }
      ],  // Predefined interval type options
      intervals: [],  // Fetched intervals (start_at and end_at)
    };
  },
  methods: {
    // Call this when interval type is selected
    fetchIntervalsList() {
      const intervalType = this.searchFields.intervalType;

      if (intervalType) {
        this.axios.get('/customer-points-center/getIntervalsList', {
          params: { intervalType }
        })
          .then(response => {
            // Assuming response.data contains the intervals with start_at and end_at
            this.intervals = response.data.data.map(interval => ({
              value: interval.id,
              text: `Start: ${interval.start_at} - End: ${interval.end_at}`
            }));
          })
          .catch(error => {
            console.error('Error fetching intervals list:', error);
          });
      }
    },

    // Method to handle field input changes
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e;  // Update the selected interval ID
    }, 500),
  },
};
</script>
