<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >

        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <LocaleTabs
                  :errors="formErrors"
                  name="name"
              >
                <template
                    v-for="(i,k,idx) in languages"
                    :slot="`lang${idx}`"
                    slot-scope="slotScope"
                >
                  <ValidationProvider
                      :key="idx"
                      v-slot="{ errors }"
                      :class="slotScope.selectedTab!==idx?'hidden':''"
                      :vid="`name.${k}`"
                      :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      rules="required"
                  >
                    <b-form-group
                        :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                          v-model="form.name[k]"
                          :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="country_id"
                :name="$t('country')"
                rules="required"
              >
                <b-form-group
                  :label="$t('country')"
                >
                  <v-select
                    v-model="form.country_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="countries"
                    :reduce="item => item.id"
                    :placeholder="$t('country')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.channel_id')"
                  vid="channel_id"
                  rules="required"
              >
                <b-form-group
                    :label="$t('labels.channel_id')"
                >
                  <b-form-input
                      v-model="form.channel_id"
                      :placeholder="$t('labels.channel_id')"
                      autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="1">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="enabled"
                unchecked-value="disabled"
                name="check-button"
                switch
              />
            </b-col>

            <b-col cols="4">
              <b-card-text class="mb-0">
                {{ $t('labels.send_push_notification_for_new_video') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.push_notification_status"
                value="1"
                unchecked-value="0"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      countries: [],
      form: {
        name: {
          en: null,
          ar: null,
        },
        channel_id: null,
        country_id: null,
        status: 'enabled',
        push_notification_status: 0
      },
    }
  },
  created() {
    this.getCountries()
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true

    }
  },
  methods: {
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name_values,
            status: data.status,
            country_id: data.country_id,
            channel_id: data.channel_id,
            push_notification_status: data.push_notification_status,
          }
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      return $form
    },
  },
}
</script>
